export default {
	data () {
		return {
			actionFullDates: {},
		}
	},
	methods: {





		/* GET ALL DATES USEFUL FOR THIS ACTIONS */
		/* Returns an object with many properties: startFullData, endFullData, currentDate */
		getFullDates(){

			let dates = {
				current: new Date(),
				registration:{
					start:  new Date(this.lead.registrationbegindate),
					end:  new Date(this.lead.registrationenddate),
				},
				participation: {
					start: new Date(this.lead.participationbegindate),
					end:  new Date(this.lead.participationextensiondate),
				},
				reparticipation: {
					start:  new Date(this.lead.participationbegindate),
					end:  new Date(this.lead.treatmentenddaterepart),
				},
				purchaseDates: {
					start: new Date(this.lead.purchasebegindate),
					end: new Date(this.lead.purchaseenddate),
				}
			};

			this.log('Dates techniques', 'group');
			this.log(dates);
			this.log('', 'groupEnd');
			return dates;
		},

		/* DISPLAY THE DATES IN THE CONSOLE */
		/* For CONSOLE INFO only */
		displayDatesInConsole(){
			this.log("Human-readable dates for this action", "sep");
			try{
				console.group("Human-readable dates for this action");
				console.log("Current:                       %c " + this.getHumanReadableDate(this.actionFullDates.current), 'color:#0000cc;');
				console.log("Registration opens on:         %c " + this.getHumanReadableDate(this.actionFullDates.registration.start), 'color:#0000cc;');
				console.log("Registration closes on:        %c " + this.getHumanReadableDate(this.actionFullDates.registration.end) + " (+ 23h59m59s included)", 'color:#0000cc;');
				console.log("Participation opens on:        %c " + this.getHumanReadableDate(this.actionFullDates.participation.start), 'color:#0000cc;');
				console.log("Participations closes on:      %c " + this.getHumanReadableDate(this.actionFullDates.participation.end) + " (+ 23h59m59s included)", 'color:#0000cc;');   
				console.log("Reparticipations closes on:    %c " + this.getHumanReadableDate(this.actionFullDates.reparticipation.end) + " (+ 23h59m59s included)", 'color:#0000cc;');            
				console.groupEnd();
			}catch(error){
				this.log(error, "alert");
			}
		},

		/* FORMAT DATE SO HUMANS CAN EASILY READ THEM */
		getHumanReadableDate(isoDate){
			let humanReadableStartDate = isoDate.toLocaleDateString("fr-BE", {
				year: "numeric",
				month: "2-digit",
				day: "2-digit",
			});
			return humanReadableStartDate;
		},
		
		/* User is too soon/late for registration or participation: he cannot enter the form */ 
		/* CONDITIONS => Must return true or false */
		isUserThereTooSoon () {
			return this.actionFullDates.current.getTime() < this.actionFullDates.registration.start.getTime();
		},
		isUserThereTooLate () {
			return this.actionFullDates.current.getTime() > this.actionFullDates.participation.end.getTime();
		},
		isUserAbleToReparticipate() {
			return this.actionFullDates.current.getTime() > this.actionFullDates.participation.end.getTime() &&
			this.actionFullDates.current.getTime() < this.actionFullDates.reparticipation.end.getTime();
		},




		checkRegistrationDates(){
			/* Too soon for registration */
			if ( this.actionFullDates.current.getTime() < this.actionFullDates.registration.start.getTime() )	{
				this.goto('beforeStart');
			}
			/*  Too late for registration */
			if ( this.actionFullDates.current.getTime() > this.actionFullDates.registration.end.getTime() ){
				this.goto('actionEnded'); 
			}
			/* Time ok for registration */
			if (	this.actionFullDates.current.getTime() > this.actionFullDates.registration.start.getTime() && 
				this.actionFullDates.current.getTime() < this.actionFullDates.registration.end.getTime() ) 
			{
				this.goto('registration');
			}
		},
		
		checkParticipationDates(){
			/* Too soon for participation */
			if ( this.actionFullDates.current.getTime() < this.actionFullDates.participation.start.getTime() ){
				this.goto('beforeStart');
			}
			/* Too late for participation */
			if ( this.actionFullDates.current.getTime() > this.actionFullDates.participation.end.getTime() ){
				this.goto('actionEnded');
			}
			/* Time ok for participation */
			if ( 
				this.actionFullDates.current.getTime() > this.actionFullDates.participation.start.getTime() && 
				this.actionFullDates.current.getTime() < this.actionFullDates.participation.end.getTime() )
			{
				this.putDataInFields();
				this.justBeforeLoadingTheFormPage();
				
			}
		},

		checkReparticipationDates(){
			if (this.actionFullDates.current.getTime() < this.actionFullDates.participation.start.getTime() ) {
				/* never occurs, as it cannot exist a reparticipation state if there is no participation. But nevertheless… */
				this.goto('beforeStart');
			}
			if ( this.actionFullDates.current.getTime() >= this.actionFullDates.reparticipation.end.getTime() ) {
				this.goto('actionEnded');
			}
			if ( this.actionFullDates.current.getTime() >= this.actionFullDates.participation.start.getTime() &&
				this.actionFullDates.current.getTime() < this.actionFullDates.reparticipation.end.getTime()
				) {
				this.putDataInFields();
			
			this.justBeforeLoadingTheFormPage();
		}
	},

},
computed: {

},

}