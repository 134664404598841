/* Fields validity check, and active/deactive buttons */

export default {
	data () {
		return {

			forceInactiveButtons : false,

			surveyMustBeFilled : true, /* It become false only if i click on the "skip" button */
			/* used on survey page to make every fields required */
			/* Probably obsolete if I use the new validity check */

			skipButtonIsClicked: false, 
			isClaimCashbackButtonActive: false,
			isSurveyClaimCashbackButtonActive: false,

			/* Just dump here all possible fields name there are */
			fieldsValidity: {
				firstName: false,
				lastName: false,
				email: false,
				IBAN: false,
				VATNumber: false,
				optin: false,
				file: false,
				conditions: false,
				title: false,
				phoneNumber1: false, 
				idPOPRetailer: false,
				selectProduct: false,
				purchaseDate: false,
				companyName1: false,
				street1: false,
				houseNumber: false,
				city: false,
				postalCode: false,
				countries: false,
				orderedItems: false,
				deliveryStreet1: false,
				deliveryHouseNumber: false,
				deliveryCity: false,
				deliveryPostalCode: false,
				countries2: false,
				/* Add some if needed — but I think they auto-add when a fields trigger it's check */
				idLeadTMD1: false,
				idLeadTMD2: false,
				idLeadTMD3: false,
			},


		}
	},


	computed: {

		isThisButtonActive(){
			return name => {
				let active = true;
				this.mustBeValidForPage[name]?.forEach(field => {
					if (!this.fieldsValidity[field]){active = false;}
				});
				return active;
			}          
		},

	},

	methods: {

		isThereARequiredFieldMissing(){
			/* Return "true" if one of all the required Fields is missing */
			let aFieldIsAmiss = false;
			this.allRequiredFields.forEach(fieldName => {
				if (!this.fieldsValidity[fieldName]){
					aFieldIsAmiss = true;
					this.log(`The field ${fieldName} is not recognized as valid`, 'alert');
				}
			});
			this.log(`There is ${aFieldIsAmiss?'at least one':'no'} field missing.`, 'low');
			return aFieldIsAmiss;
		},

		updateValidityOfAField(val){
			this.log(`I'm on page ${this.currentPage} trying to update validity of field ${val.fieldname} with ${val.valid}`, 'low');
			this.fieldsValidity[val.fieldname] = val.valid;
		},






		/* ! recent name for same function */
		updateValidityOfThisField(val){
			this.checkIfMustUnlockClaimCashbackButton(val);
		},

		checkIfMustUnlockClaimCashbackButton(val){
			this.log(`One input is sending ${val.valid} for field ${val.fieldname}.`, 'low');
			if(val.fieldname === "file"){
				let yesno = val.valid ? '' : 'not ' ;
				this.log(`I inform you that a file is ${yesno}present in the upload field`, 'low');
				this.isUploadPresent = val.valid;
			}
			this.updateValidityOfAField(val);
			this.consoleFieldsValidityOnCurrentPage();
		},







		/* This function only displays info in the console */
		consoleFieldsValidityOnCurrentPage(){
			this.log(`All fields current validity`, 'group');
			for (let item in this.fieldsValidity){
				this.log(`${item}: ${this.fieldsValidity[item]}`, this.fieldsValidity[item]?'green': 'red');
			}
			this.log('','groupEnd');
		},

		/* To force button to become inactive */
		deactiveAllButtons(){
			this.log("Make all buttons inactive", 'low');
			this.forceInactiveButtons = true;
			this.log("Buttons are deactivated", 'low');
		},

		/* To force button to become available */
		activeAllButtons(){
			this.log("Buttons becomes activables again", 'low');
			this.forceInactiveButtons = false;
		},

	},

}
